import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./home_container.css";
import "./home_tooltip.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import config from './config';

const Home = () => {
  const [stats, setStats] = useState({ count: 0, totalReward: 0, countMiner: 0, lastNetworkHashps: 0, poolHash: 0 });
  const [valueYourHash, setValueYourHash] = useState('');
  const [valueTHADay, setValueTHADay] = useState(0);


  useEffect(() => {
    fetch(`${config.API_BASE_URL}/api/pool-mining-history/stats`)
        .then(response => response.json())
        .then(data => setStats(data))
        .catch(error => console.error('Error fetching mining stats:', error));
  }, []);


  const handleChangeYourHash = (e) => {

    const yourHash = e.target.value;
    // Разрешаем ввод только целых чисел
    if (/^\d*$/.test(yourHash)) {
      setValueYourHash(yourHash);
      const THADay = stats.lastNetworkHashps ? ((((yourHash * 100) / stats.lastNetworkHashps) * 144) / 100) * 50 : 0;
      setValueTHADay(parseFloat(THADay.toFixed(2)));
    }
  };

  const apy = stats.lastNetworkHashps ? (144 * 50 * 364 * 1000) / stats.lastNetworkHashps : 0 ;



  return (
    <div
      id="home-section"
      className="pt-18 z-0 p-2 w-full h-100vh rounded-3xl"
    >
      <div className=" text-center space-y-2 py-2">
        <h1>Welcome to THA Pool !</h1>
      </div>
      <div className="row">
        <div className="col-md-7 col-xl-4">
          <div className=" text-center row bg-[#110e26] rounded-lg w-full m-3 p-4 space-y-4 shadow-border-blue-500 flex items-center">
          <div
            className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
            id="headlessui-disclosure-panel-:r3b:"
            data-headlessui-state="open"
          >
            <div className="flex-container">
              <div>
                <h1 className="main-text">
                  <label>
                    {Math.floor(stats.lastNetworkHashps)}
                  </label>
                </h1>
                <div className="info-text">
                  Network Hashps
                </div>
              </div>
              <div>
                <h1 className="main-text ">
                  <label className="slash">
                    /
                  </label>
                </h1>
              </div>
              <div>
                <h1 className="main-text ">
                  <label className="info-roi">
                    {Math.floor(apy)}%
                  </label>
                </h1>
                <h3 className="info-text">
                  APY
                  <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='custom-tooltip'>
                        Annual Percentage Yield, accounting for the reinvestment of mined THA coins
                      </Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          className="ml-1"
                          style={{ verticalAlign: "top" }}
                        />
                    </OverlayTrigger>
                </h3>
              </div>
            </div>
          </div>
          <div
            className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
            id="headlessui-disclosure-panel-:r3b:"
            data-headlessui-state="open"
          >
            <h1 className="main-text">
              <label>
                {Math.floor(stats.poolHash)}
              </label>
            </h1>
            <h3 className="info-text">
              Pool Hash
            </h3>
          </div>
          <div
            className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
            id="headlessui-disclosure-panel-:r3b:"
            data-headlessui-state="open"
          >
            <h1 className="main-text">
              <label>
                {stats.countMiner}
              </label>
            </h1>
            <h3 className="info-text">
              Miners
            </h3>
          </div>
          <div
            className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
            id="headlessui-disclosure-panel-:r3b:"
            data-headlessui-state="open"
          >
            <h1 className="main-text">
              <label>
                3%
              </label>
            </h1>
            <h3 className="info-text">
              Pool Fee
            </h3>
          </div>
          <div
            className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
            id="headlessui-disclosure-panel-:r3b:"
            data-headlessui-state="open"
          >
            <div className="flex-container">
              <div>
                <h1 className="main-text">
                  <label>
                    {stats.count}
                  </label>
                </h1>
                <h3 className="info-text">
                  Blocks mining
                </h3>
              </div>
              <div>
                <h1 className="main-text ">
                  <label className="slash">
                    /
                  </label>
                </h1>
              </div>
              <div>
                <h1 className="main-text">
                  <label>
                    {Math.floor(Number(stats.totalReward))}
                  </label>
                </h1>
                <h3 className="info-text">
                  THA mined
                </h3>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-md-7 col-xl-7">
          <div className="container_byu">
            <div className="brand-title text-center">THA mining calculator</div>
            <div className="inputs">
                <div className="row pt-3">
                  <div className="col-sm-6 col-md-6 col-xl-4 items-center row text-left text-sm-right">
                    <label>Your Hash:
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='custom-tooltip'>
                        Your hash rate is calculated by multiplying the number of coins by 10
                      </Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          className="ml-1"
                          style={{ verticalAlign: "top" }}
                        />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-sm-6 col-md-6 col-xl-5 container-right container-sm-left">
                    <div className="container_byu_input">
                      <input
                        className="byu_input"
                        type="text"
                        placeholder="0"
                        value={valueYourHash}
                        onChange={handleChangeYourHash}
                      />
                    </div>
                  </div>
                </div>

                <div className="row pt-4">
                  <div className="col-sm-6 col-md-6 col-xl-4 items-center row text-left text-sm-right">
                    <label>THA Day:
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='custom-tooltip'>
                        The calculated amount of THA is theoretical; in practice, deviations may occur in either direction
                      </Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          className="ml-1"
                          style={{ verticalAlign: "top" }}
                        />
                      </OverlayTrigger>
                  </label>
                </div>
                <div className="col-sm-6 col-md-6 col-xl-5 container-right container-sm-left text-2xl">
                  <h1 className="main-text">
                      <label>
                        {valueTHADay}
                      </label>
                    </h1>
                </div>
                </div>

                <hr className="custom-hr"/>

                
                <div className="row pt-3">
                    <div className=" col-10">
                      <label>
                      <span className="section-title">How to Join the Pool:</span> <br />

                      - Click the button below to access our Telegram @ThaPoolBot <br />
                      - Obtain your unique Deposit address <br />
                      - Transfer THA coins to this address <br />
                      - After some time, check your hash rate in tab "Miners" <br />
                      <br />
                      <span className="section-title">How to Withdraw THA Coins from the Pool:</span><br />
                      - Open the Telegram @ThaPoolBot<br />
                      - Navigate to the coin withdrawal menu<br />
                      - Submit a request for the desired amount of THA<br />
                      - The request is processed manually within 24 hours<br />
                      </label>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className=" col-md-10 col-xl-7">
                      <button className="container_byu_button " 
                        // disabled={isButtonDisabled} 
                        onClick={() => {
                                  window.open("https://t.me/ThaPoolBot", "_blank");
                                }}>
                          <FontAwesomeIcon icon={faTelegram} size="2xl" className="pr-1"/>
                          Join THA-pool
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
