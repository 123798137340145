import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./main.css";
import Header from "./Components/Header";
import Home from "./Home";
import Poolblocks from "./Poolblocks";
import Miners from "./Miners";
import MinerDetails from './MinerDetails';

function App() {
  return (
      <BrowserRouter>
        <div className="min-h-screen bg-black text-white flex w-full flex-col">
          <Header />
          <div className="app-main">
            <div className="app-main__outer bg-[#060030]">
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home key={Home} />
                    </>
                  }
                />
                <Route path="/poolblocks" element={<Poolblocks key={Poolblocks} />} />
                <Route path="/miners" element={<Miners key={Miners} />} />
                <Route path="/miners/:address" element={<MinerDetails key={MinerDetails} />} />
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
  );
}

export default App;
