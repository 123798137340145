import React, { useEffect, useState } from "react";
import moment from 'moment';
import ScrollToTopButton from './Components/ScrollToTopButton';
import config from './config';
import Loading from './Components/Loading';
import { Link } from "react-router-dom";
moment.defaultFormatUtc = true;



const Miners = () => {
  const [miners, setMiners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true); 
      const response = await fetch(`${config.API_BASE_URL}/api/miners`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMiners(data);
      setIsLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div
      id="home-section"
      className="pt-18 z-0 p-2 my-2 w-full rounded-3xl"
    >
      <div className="text-center space-y-12 ">
        <h1 className="pb-3">
              Miners
            </h1>
      </div>
      <div className="row">
        <div className="col-12 col-xl-10 col-xxl-9">
          <div className="column">
              <div className="table-container">
                <div className="table-responsive">
                  <table>
                      <thead>
                        <tr>
                          <th>№</th>
                          <th>Deposit Address</th>
                          <th>Payout Address</th>
                          <th>Telegram Username</th>
                          <th>Hashrate</th>
                          <th>Pool Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                          {miners.map((miner, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="tx-wtxid">
                                <Link to={`/miners/${miner.replenishmentAddress}`}>
                                  {miner.replenishmentAddress}
                                </Link>
                              </td>
                              <td>{miner.payoutAddress}</td>
                              <td>{miner.telegramUsername}</td>
                              <td>{Math.floor(miner.hashrate)}</td>
                              <td>{miner.poolPercentage.toFixed(2)}%</td>
                              
                            </tr>
                          ))}
                          
                      </tbody>
                  </table>
                  {isLoading && (
                     <Loading/>
                    ) 
                  }
                </div>
              </div>
          </div>
        </div>
      </div>
      
      <ScrollToTopButton />
    </div>
  );
};

export default Miners;