import React, { useState } from 'react';
import '../Tabs.css';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[1].key);

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={`tab-button ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      {tabs.map((tab) => (
        <div key={tab.key} className={`tab-content ${activeTab === tab.key ? 'active' : ''}`}>
          {activeTab === tab.key && <div className="container_tab">{tab.content}</div>}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
