import "../loader.css";

const Loading = () => {

    return (
        <div className="container">
              <div className="loader">
                <div className="ball moving"></div>
                <div className="balls">
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball moving"></div>
                </div>
              </div>

              <svg>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                </filter>
              </svg>
            </div>
      );
    };
    
    export default Loading;