import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from './config';
import ScrollToTopButton from './Components/ScrollToTopButton';
import Loading from './Components/Loading';
import Tabs from './Components/Tabs';
const MinerDetails = () => {
  const { address } = useParams();
  const [minerData, setMinerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/miners/${address}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMinerData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [address]);

  const formatNumber = (number) => {
    return Math.floor(number * 100) / 100;
  };

  const calculateTotalRewards = () => {
    return minerData.rewards?.reduce((acc, reward) => acc + reward.rewardAmount, 0) || 0;
  };

  const truncateMiddle = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
  
    const startLength = Math.ceil((maxLength - 3) / 2);
    const endLength = Math.floor((maxLength - 3) / 2);
    return `${str.substr(0, startLength)}...${str.substr(str.length - endLength)}`;
  };

  const tabs = [
    {
      key: 'replenishments',
      title: 'Deposits',
      content: (
        <div className="table-container">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Transaction</th>
                </tr>
              </thead>
              <tbody>
                {minerData?.replenishments?.map((replenishment, index) => (
                  <tr key={index}>
                    <td>{replenishment.replenishmentDate}</td>
                    <td>{replenishment.replenishmentAmount}</td>
                    <td className="tx-wtxid">
                      <a
                        href={`https://explorer-2.tha-chain.org/tx/${replenishment.replenishmentTxid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {truncateMiddle(replenishment.replenishmentTxid, 24)}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      key: 'rewards',
      title: 'Rewards',
      content: (
        <div className="table-container">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Block hash</th>
                  <th>№ Block</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {minerData?.rewards?.map((reward, index) => (
                  <tr key={index}>
                    <td>{reward.rewardDate}</td>
                    <td className="tx-wtxid">
                      <a
                        href={`https://explorer-2.tha-chain.org/block/${reward.rewardBlock}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {truncateMiddle(reward.rewardBlock, 24)}
                      </a>
                    </td>
                    <td>{formatNumber(reward.blockHeight)}</td>
                    <td>{formatNumber(reward.rewardAmount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      key: 'withdrawals',
      title: 'Withdrawals',
      content: (
        <div className="table-container">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                    <th>Created Date</th>
                    <th>Amount</th>
                    <th>Processed</th>
                    <th>Date</th>
                    <th>Transaction</th>
                </tr>
              </thead>
              <tbody>
                {minerData?.withdrawals?.map((withdrawal, index) => (
                  <tr key={index}>
                    <td>{withdrawal.withdrawalCreatedDate ? withdrawal.withdrawalCreatedDate : 'N/A'}</td>
                    <td>{withdrawal.withdrawalAmount}</td>
                    <td>{withdrawal.withdrawalProcessed ? 'Successful' : 'Processing'}</td>
                    <td>{withdrawal.withdrawalDate ? withdrawal.withdrawalDate : 'N/A'}</td>
                    <td className="tx-wtxid">
                      {withdrawal.withdrawalTxid ? (
                        <a
                          href={`https://explorer-2.tha-chain.org/tx/${withdrawal.withdrawalTxid}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {truncateMiddle(withdrawal.withdrawalTxid, 24)}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div
      id="home-section"
      className="pt-18 z-0 p-2 my-2 w-full rounded-3xl"
    >
      <div className="text-center space-y-12 ">
        <h1 className="pb-3">Miner Details</h1>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="row">
            <div className="col-7 col-md-5 col-xl-4 col-xxl-3">
                <div className=" text-center row bg-[#110e26] rounded-lg w-full m-3 p-4 space-y-4 shadow-border-blue-500 flex items-center">
                <div
                    className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
                    id="headlessui-disclosure-panel-:r3b:"
                    data-headlessui-state="open"
                >
                    <h1 className="main-text">
                        <label>
                            {minerData.hashrate ? Math.floor(minerData.hashrate) : 'N/A'}
                        </label>
                    </h1>
                    <h3 className="info-text">
                        Hashrate
                    </h3>
                </div>
                <div
                    className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
                    id="headlessui-disclosure-panel-:r3b:"
                    data-headlessui-state="open"
                >
                    <h1 className="main-text">
                        <label>
                        {formatNumber(calculateTotalRewards())}
                        </label>
                    </h1>
                    <h3 className="info-text">
                        Total Earned
                    </h3>
                </div>
                <div
                    className="bg-[#3d385a] rounded-md w-full px-2 py-4 border-l-4 border-[#28A0F0] overflow-hidden text-2xl"
                    id="headlessui-disclosure-panel-:r3b:"
                    data-headlessui-state="open"
                >
                    <h1 className="main-text">
                        <label>
                            {minerData.poolPercentage ? minerData.poolPercentage.toFixed(2) : 'N/A'}%
                        </label>
                    </h1>
                    <h3 className="info-text">
                        Pool Percentage
                    </h3>
                </div>
                </div>
            </div>
          <div className="col-12 col-xl-8 col-xxl-8">
            <h1 className="p-1 m-2">{address}</h1>
            <Tabs tabs={tabs} />
          </div>
        </div>
      )}
      <ScrollToTopButton />
    </div>
  );
};

export default MinerDetails;
